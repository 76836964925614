import PropTypes from 'prop-types'

import * as S from '../styles'

import Heading from './Heading'

const Question = ({ children, title, ...headingProps }) => (
  <S.Block itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
    <Heading itemProp="name" as="h3" format="hyphen" size="large" {...headingProps}>
      {title}
    </Heading>
    <div itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
      <S.Answer itemProp="text">{children}</S.Answer>
    </div>
  </S.Block>
)

Question.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
}

export default Question
