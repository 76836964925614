import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import PropTypes from 'prop-types'

import * as S from '../styles'

const Heading = ({ as, children, ...props }) => (
  <S.BlockHeading>
    <SubHeading {...props} type={as}>
      {children}
    </SubHeading>
  </S.BlockHeading>
)

Heading.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Heading
